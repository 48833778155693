<template>
  <portal to="dialog_top">
    <ws-dialog
        @save="selectImage"
        v-model="display"

        width="800"
        not-centered
        max-height="640px"
        height="90%"
        :save-text="$t('Choose')"
        :disable-save="!select && select !== 0"
        :cancel-text="$t('Return')"
    >

      <template #header>
        <div class="d-flex justify-space-between align-center pb-3 px-6 pt-5" >
          <h3 :style="`color : ${wsDARKER}; font-weight: 600; font-size: ${!SM ? 20 : 16}px`">
            {{  $t('AddImage')  }}
          </h3>
          <v-btn
              @click="$emit('input' , false)"
              class="mr-n2"
              :color="wsDARKER"
              icon
          >
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </div>
        <ws-navigation
            @input="search = ''; select = null"
            v-model="navigation"
            :items="navigationSelect"
            no-number
            padding="2px"
        />
        <div class="pb-3 px-6 mt-n5" >
          <div class="d-flex align-center">
            <ws-text-field
                icon="mdi-magnify"
                :placeholder="$t('SearchImages')"
                v-model="search"
                width="100%"
                class="mr-2"
                clearable
            />
            <template v-if="navigation !== 'unsplash'">
              <v-sheet
                  @click="gridView = false"
                  :color="!gridView ? (wsATTENTION + '44') : 'transparent' "
                  class="d-flex align-center justify-center wsRoundedLight pointer"
                  height="34"
                  width="34"
                  icon
                  v-ripple
              >
                <v-icon size="20" :color="!gridView ? wsATTENTION : wsACCENT">mdi-view-agenda-outline</v-icon>
              </v-sheet>
              <v-sheet
                  @click="gridView = true"
                  :color="gridView ? (wsATTENTION + '44') : 'transparent' "
                  class="d-flex align-center justify-center wsRoundedLight pointer"
                  height="34"
                  width="34"
                  icon
                  v-ripple
              >
                <v-icon :color="gridView ? wsATTENTION : wsACCENT" size="20">mdi-grid-large</v-icon>
              </v-sheet>
            </template>
            <ws-button
                v-else
                @click="getUnsplashStorage(false , search , true)"
                label="Search"
                :loading="LOADING"
                @keydown.enter="getUnsplashStorage(false , search , true)"
                :disabled="!search"
                icon="mdi-search"
            />
          </div>
        </div>
      </template>

      <template #default>
        <div v-if="navigation === 'my_library'">
          <v-sheet v-if="itemsFiltered.length === 0" style="height: 100vh !important; max-height: 360px !important"  class="fill-height">
            <ws-blank-page
                icon="mdi-image"
                :text="!search ? 'YouHaveNoImagesYet' : null"
                :description="search ? 'NoImagesSearchDescription' : null"
                :color="!!search ? wsBACKGROUND : null"
            />
          </v-sheet>
          <v-row v-else-if="gridView" class="mt-2">
            <v-col
                v-for="(item, i) in itemsFiltered" :key="i"
                cols="3">
              <v-hover #default="{hover}" >
                <v-sheet :color="select === item.uuid ? wsLIGHTCARD : 'transparent'" class="wsRoundedLight">
                  <v-sheet
                      @click="select = i"
                      :color="wsBACKGROUND"
                      class="wsRoundedLight pointer"
                      style="position: relative"
                      height="100"
                  >
                    <ws-img
                        :src="item.url"
                        height="100"
                        auto-size
                        v-ripple
                        :image-style="hover || select === i ? 'opacity : 0.3' : null"
                    />
                    <v-sheet style="position: absolute; top : 10px; left: 10px;border-radius: 4px" height="18" width="18">
                      <v-icon
                          :color="wsACCENT"
                          style="margin-left: -3px;margin-top : -8px;">
                        {{ select === i ?  'mdi-checkbox-marked-outline' :  'mdi-checkbox-blank-outline' }}
                      </v-icon>
                    </v-sheet>
                  </v-sheet>
                  <h5 class="shorten-text" :style="`color : ${wsACCENT};font-size : 12px`"> {{ item.name }}</h5>
                </v-sheet>
              </v-hover>

            </v-col>
          </v-row>
          <template v-else>
            <v-hover v-for="(item, i) in itemsFiltered"
                     #default="{hover}"
                     :key="i"
            >
              <v-sheet
                  @click="select = i"
                  :style="`border : 1px solid ${wsBACKGROUND} !important`"
                  class="d-flex align-center pa-2 wsRoundedLight mt-2 pointer"
                  :class="[{'mt-5' : i === 0}]"
                  :color="hover || select === i ? wsLIGHTCARD : 'transparent'"
              >

                <v-icon
                    :color="wsACCENT"
                    class="mr-3"
                    size="20"
                >
                  {{ select === i ?  'mdi-checkbox-marked-outline' :  'mdi-checkbox-blank-outline' }}
                </v-icon>

                <v-sheet :color="wsBACKGROUND" class="mr-3">
                  <ws-img
                      :src="item.url"
                      height="40"
                      width="40"

                      auto-size
                      v-ripple
                  />
                </v-sheet>


                <h5 class="shorten-text" :style="`color : ${wsACCENT};font-size : 12px`"> {{ item.name }}</h5>
              </v-sheet>
            </v-hover>

          </template>
        </div>
        <div v-if="navigation === 'unsplash'">


          <v-sheet v-if="LOADING && unsplashItems.length === 0"
                   style="height: 100vh !important; max-height: 360px !important"
                   class="d-flex align-center justify-center">
            <v-progress-circular
                v-if="LOADING"
                :color="wsACCENT"
                size="60"
                indeterminate
            />

          </v-sheet>
          <v-sheet v-else-if="unsplashItems.length === 0 "
                   style="height: 100vh !important; max-height: 360px !important"
                   class="d-flex align-center justify-center">
            <ws-blank-page
                icon="mdi-image"
                :text="null"
                :description="search ? 'NoImagesSearchDescription' : 'EnterSearchUnsplash'"
                :color="wsBACKGROUND"
            />
          </v-sheet>



          <template v-else>
            <v-row class="mt-2">
              <v-col
                  v-for="(item, i) in unsplashItems" :key="i + 'unsplash'"
                  cols="3">
                <v-hover #default="{hover}" >
                  <div>
                    <v-sheet
                        @click="select = i"
                        :color="wsBACKGROUND"
                        class="wsRoundedLight pointer"
                        style="position: relative"
                        height="100"
                    >
                      <v-img
                          :src="item.preview"
                          :style="hover || select === i ? 'opacity : 0.3' : null"
                          class="wsRoundedLight"
                          height="100"
                          v-ripple
                      />
                      <v-sheet style="position: absolute; top : 10px; left: 10px;border-radius: 4px" height="18" width="18">
                        <v-icon
                            :color="wsACCENT"
                            style="margin-left: -3px;margin-top : -8px;">
                          {{ select === i ?  'mdi-checkbox-marked-outline' :  'mdi-checkbox-blank-outline' }}
                        </v-icon>
                      </v-sheet>
                    </v-sheet>
                  </div>
                </v-hover>

              </v-col>
            </v-row>
            <div class="d-flex mt-5">
              <ws-button
                  :loading="LOADING"
                  class="mx-auto mt-3"
                  @click="loadMoreUnsplash"
                  text
                  :label="$t('LoadMore')"
              />
            </div>
          </template>



        </div>
        <div v-if="navigation === 'westudy_icons'">
          <div v-for="(category, i) in iconSet" :key="i">
            <h5 style="font-size: 13px" :style="`color : ${wsACCENT}`">{{ category.title }}</h5>
            <table style="width: 100%">
              <template v-for="row in category.items.length%8">
                <tr :key="row + 'row'">
                  <template v-for="index in 9">
                    <td  v-if="category.items[index + ((row-1)*9) - 1] || false" :key="index + row + 'item'">
                      <v-hover #default="{hover}" >
                        <v-sheet
                            :color="select === category.items[index + ((row-1)*9) - 1] ? wsLIGHTCARD : 'transparent'"
                            class="wsRoundedLight pa-2"
                            height="70" width="70"
                            v-ripple
                        >
                          <v-sheet
                              @click="select = category.items[index + ((row-1)*9) - 1]"
                              class="wsRoundedLight pointer"
                              style="position: relative"
                              width="100%"
                              height="100%"
                              color="transparent"
                          >
                            <ws-img
                                :src="WESTUDY_ICON(category.items[index + ((row-1)*9) - 1]) "
                                :style="hover || select === i ? 'opacity : 0.3' : null"
                                class="wsRoundedLight"
                                width="100%"
                                height="100%"
                                contain
                            />
                          </v-sheet>
                        </v-sheet>
                      </v-hover>
                    </td>
                  </template>
                </tr>
              </template>

            </table>
            <v-divider class="mt-2 mb-4" :color="wsBACKGROUND"/>
          </div>
        </div>
      </template>



    </ws-dialog>
  </portal>
</template>

<script>
import {mapActions} from "vuex";
import iconSetSource from "@/assets/json/avalon/avalon_icon_set.json"
import wsBlankPage from "@/components/UI/wsBlankPage";
export default {
  name: "imageLibraryDialog",
  components: {
    wsBlankPage
  },

  props : {
    value : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      navigation : 'my_library',
      items : [],
      select : null,
      search : null,
      iconSet : [],
      gridView : true,

      unsplashItems : [],
      unsplashPage : 1,
      unsplashSearch : null,
      unsplashSelect : null,

    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      if ( this.search ) {
        items = items.filter( el => el.name.toLowerCase().includes(this.search.toLowerCase()))
      }

      items = items.filter(el => el.mime.includes('image'))

      return items
    },
    navigationSelect() {
      return [
        { text : this.$t('MyLibrary') , value : "my_library" },
        { text : 'Unsplash' , value : "unsplash" },
        { text : 'WeStudy Icons' , value : 'westudy_icons'  }
      ]
    }
  },
  watch : {
    navigation(value) {
      if ( value === 'unsplash' && this.unsplashItems.length === 0 ) {
        this.getUnsplashStorage()
      }
    },
    display(value) {
      this.$store.state.avalon.imageLibraryOn = value
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.display !== this.value ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('storage', [ 'GET_STORAGE_BROWSER' ]),
    ...mapActions('avalon', [ 'GET_UNSPLASH_BROWSER' ]),

    selectImage() {
      let image = ''
      if ( this.navigation === 'my_library' ) {
        image = this.itemsFiltered[this.select].url
      }
      if ( this.navigation === 'unsplash' ) {
        image = this.unsplashItems[this.select].url
      }
      if ( this.navigation === 'westudy_icons' ) {
        image = this.WESTUDY_ICON(this.select)
      }

      this.$emit('select' , image)
      this.display = false
    },
    loadMoreUnsplash() {
      this.unsplashPage++
      this.getUnsplashStorage(true , this.search || null )
    },
    async getStorage() {
      let result = await this.GET_STORAGE_BROWSER()
      if ( !result || result === true) {
        return
      }
      result.reverse()
      this.items = result
    },
    async getUnsplashStorage(loadMore = false , searchQuery = null , flush = false) {
      if ( flush ) {
        this.unsplashItems = []
      }
      let data = {
        page : this.unsplashPage
      }
      if ( searchQuery ) {
        data.query = searchQuery
      }

      let result = await this.GET_UNSPLASH_BROWSER(data)
      if ( !result || result === true) {
        return
      }
      if ( !loadMore ) {
        this.unsplashItems = result
      } else {
        result.forEach(el => {
          this.unsplashItems.push(el)
        })
      }


    }
  },
  mounted() {
    this.iconSet = iconSetSource
    this.display = this.value
    this.getStorage()
  }
}
</script>

<style scoped>

</style>