<template>
  <div v-if="!loading"
       class="wsRoundedLight
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6 " >

    <v-sheet  width="500">
      <div class="d-flex justify-center">
        <v-icon size="80" :color="color || wsDARKLIGHT" >{{ icon }}</v-icon>
      </div>
      <h3 :style="color ? `color : ${color}` : null"
          style="font-size: 22px; font-weight: 700" class="text-center  my-6">{{ $t(text) }}</h3>

      <h4 v-if="description"
          :style="color ? `color : ${color}` : null"
          style="font-size: 16px;" class="text-center  my-6">{{ $t(description) }}</h4>

      <div v-if="buttonText " class="d-flex justify-center">
        <v-btn @click="buttonAction()"  height="40" :to="route" dark :color="wsATTENTION" class="noCaps">
          <v-icon v-if="prependButtonIcon" class="mr-1">{{ prependButtonIcon }}</v-icon>
          {{ $t(buttonText) }}
          <v-icon v-if="appendButtonIcon">{{ appendButtonIcon }}</v-icon>
        </v-btn>
      </div>
    </v-sheet>

  </div>
  <v-sheet v-else class="fill-height d-flex align-center justify-center">
    <v-progress-circular indeterminate :color="wsACCENT" />
  </v-sheet>
</template>

<script>
export default {
  name: "wsBlankPage",
  props : {
    text : {
      type : String,
      default : 'No content'
    },
    description : {
      type : String,
    },
    icon : {
      type : String,
      default : "mdi-database-search-outline"
    },
    buttonText : {
      type : String
    },
    buttonAction : {
      type : Function,
      default() { return () => {}  }
    },
    appendButtonIcon: {
      type : String,
    },
    prependButtonIcon: {
      type : String,
    },
    route : {
      type : String,
      default : null
    },
    loading : {
      type : Boolean,
      default : false
    },
    color : {
      type : String
    }
  }
}
</script>

<style scoped>

</style>